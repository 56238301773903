import React from 'react';
import { Link } from 'gatsby';

import Button from '@youship/components/objects/button';
import Illustration from 'images/not-found-illustration-background.inline.svg';

import './styles.scss';

const NotFoundContent = () => (
  <div className="not-found">
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col col-lg-6 not-found__text">
          <div className="not-found__subtitle">
            Ooops!!
          </div>
          <div className="not-found__title">
            Page not found
          </div>

          <div className="not-found__description">
            Sorry, the order fell. Now go back.
          </div>
          <Link
            to="/"
            className="not-found__button-link"
          >
            <Button
              text="Back to Homepage"
              context="primary"
            />
          </Link>
        </div>
        <div className="col col-12">
          <Illustration
            className="not-found__illustration"
          />
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundContent;
